import { isEmpty, isEqual } from 'lodash'
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import Popup from 'reactjs-popup'
import { DropdownItem, Input, Label } from 'reactstrap'

import { SpotWorkerShiftStatus } from 'api/spot_workers/constants'
import type { SpotWorkerColumnDataType } from 'api/spot_workers/types'

import { showSuccess } from 'slices/notificationSlice'
import {
  putSpotWorkersStatusUpdate,
  getAvailableSpotWorkers,
  selectSpotWorkerStatus,
  deleteSpotWorkers,
  putSpotWorkersStatusSickout,
  putSpotWorkerNew,
  assignSpotWorkerExistingId,
} from 'slices/spotWorkerSlice'

import useDateQuery from 'hooks/useDateQuery'
import useSpotWorker from 'hooks/useSpotWorker'

import { EditTextForm, InputSearchBox, SelectDropdown, SelectTimeForm } from './TableInputForms'
import {
  Actions,
  getActionList,
  getSpotWorkerShiftStatus,
  getStatusColor,
  isValidTimeSelection,
  UNREGISTERED_VALUE_ID,
  UNSELECTED_TIME_LABEL,
} from './utils'

import styles from './SpotWorkerTable.module.scss'

import type { ActionType } from './utils'

type Props = {
  centerColContainerRef: React.RefObject<HTMLDivElement>
  initDataColumn?: SpotWorkerColumnDataType
  editDataColumn: SpotWorkerColumnDataType
  addWorkTimeRow2: boolean
  addWorkTimeRow3: boolean
  handleColumnChange: (lineNumber: number, key: string, value: { id: number; name: string } | string | null) => void
  handleSelectedUserCheck: (index: number) => void
  selectedUser: number[]
}

export const SpotWorkerTableColumn = ({
  centerColContainerRef,
  initDataColumn,
  editDataColumn,
  addWorkTimeRow2,
  addWorkTimeRow3,
  selectedUser,
  handleSelectedUserCheck,
  handleColumnChange,
}: Props) => {
  const dispatch = useDispatch()
  const { checkValidColumn, checkIsDateInEditableRange, targetWorkspaces, targetGroups, targetTemplates } =
    useSpotWorker()
  const workDate = useDateQuery()
  const { availableSpotWorkers, isRequesting, errorMessage, failedColumnNames } = useSelector(
    selectSpotWorkerStatus,
    shallowEqual
  )

  const [submitted, setSubmitted] = useState(false)
  const isSickoutDisabled = useMemo(() => editDataColumn.sickout, [editDataColumn.sickout])
  const isEditDisabled = useMemo(() => !checkIsDateInEditableRange(workDate), [checkIsDateInEditableRange, workDate])
  const isSelectedUser = useMemo(() => selectedUser.includes(editDataColumn.lineNumber), [selectedUser, editDataColumn])
  const isEdited = useMemo(() => !isEqual(initDataColumn, editDataColumn), [initDataColumn, editDataColumn])
  const isValid = useMemo(() => checkValidColumn(editDataColumn), [checkValidColumn, editDataColumn])

  useEffect(() => {
    if (!submitted || isRequesting) {
      return
    }
    if (errorMessage === '' && isEmpty(failedColumnNames)) {
      dispatch(showSuccess())
    }
    setSubmitted(false)
  }, [dispatch, errorMessage, failedColumnNames, isRequesting, submitted])

  const getDisabledColor = useCallback((disabled?: boolean) => (disabled ? 'text-muted opacity-50' : ''), [])
  const getPopupContent = useCallback(
    (shiftStatus: number) => {
      const lackData: string[] = []
      switch (shiftStatus) {
        case SpotWorkerShiftStatus.ShiftRegistered:
          return ['最新の情報がシフトに反映されています']
        case SpotWorkerShiftStatus.ShiftUnregistered:
          return ['シフト未登録です', '作業計画の編集ができません']
        case SpotWorkerShiftStatus.Insufficient:
        case SpotWorkerShiftStatus.InsufficientWithoutId:
          if (!editDataColumn.workerId) {
            lackData.push('メンバーID')
          }
          if (!editDataColumn.workspace) {
            lackData.push('配属先ワークスペース')
          }
          if (!isValidTimeSelection(editDataColumn.workStart1, editDataColumn.workEnd1)) {
            lackData.push('勤務時間')
          }
          return ['必須情報が不足しています', ...lackData]
        case SpotWorkerShiftStatus.UnUpdated:
          return ['編集内容がシフトに反映されていません']
        case SpotWorkerShiftStatus.Sickout:
          return ['欠勤として登録済み', '作業計画の編集ができません']
        default:
          return ['']
      }
    },
    [editDataColumn]
  )

  // 変更がある場合のみ保存データを作成
  const saveData = useMemo(
    () =>
      isEdited
        ? [
            {
              revision: editDataColumn.revision,
              workerId: editDataColumn.workerId,
              lineNumber: editDataColumn.lineNumber,
              workspaceId: editDataColumn.workspace?.id || null,
              groupId: editDataColumn.group?.id || null,
              wmsMemberId: editDataColumn.wmsMemberId,
              workTemplateId: editDataColumn.template?.id || null,
              workStart1: editDataColumn.workStart1,
              workEnd1: editDataColumn.workEnd1,
              workStart2: editDataColumn.workStart2,
              workEnd2: editDataColumn.workEnd2,
              workStart3: editDataColumn.workStart3,
              workEnd3: editDataColumn.workEnd3,
            },
          ]
        : [],
    [editDataColumn, isEdited]
  )

  const statusChangeData = useMemo(
    () => [
      {
        lineNumber: editDataColumn.lineNumber,
        revision: editDataColumn.revision,
      },
    ],
    [editDataColumn]
  )

  const isRegistered = useMemo(
    () =>
      editDataColumn.status === SpotWorkerShiftStatus.ShiftRegistered ||
      editDataColumn.status === SpotWorkerShiftStatus.UnUpdated,
    [editDataColumn]
  )

  const handleActionClick = useCallback(
    (actionId: ActionType) => {
      switch (actionId) {
        case Actions.Update:
        case Actions.Register:
          dispatch(putSpotWorkersStatusUpdate(workDate, statusChangeData, saveData))
          break
        case Actions.Delete:
          dispatch(deleteSpotWorkers(workDate, statusChangeData, saveData))
          break
        case Actions.Sickout:
          dispatch(putSpotWorkersStatusSickout(workDate, statusChangeData, saveData))
          break
        default:
          break
      }
      setSubmitted(true)
    },
    [dispatch, statusChangeData, saveData, workDate]
  )

  const workerNameCel = useMemo(
    () => (
      <div className="d-flex flex-row column-gap-2 align-items-center">
        <Input
          type="checkbox"
          checked={isSelectedUser}
          onClick={() => handleSelectedUserCheck(editDataColumn.lineNumber)}
          className="mt-0"
        />
        <Label check className={`${getDisabledColor(isSickoutDisabled)} text-truncate`}>
          {editDataColumn.name}
        </Label>
        {!isValid && <i className="icf-alert text-danger font-large" />}
      </div>
    ),
    [isSickoutDisabled, isSelectedUser, getDisabledColor, editDataColumn, isValid, handleSelectedUserCheck]
  )

  const shiftStatusCel = useMemo(() => {
    const popupLines = getPopupContent(editDataColumn.status).filter(line => line !== '')
    return (
      <Popup
        trigger={
          <div className={getDisabledColor(isSickoutDisabled)}>{getSpotWorkerShiftStatus(editDataColumn.status)}</div>
        }
        position="bottom center"
        on="hover"
        arrowStyle={{ color: 'black' }}
        offsetX={-30}
      >
        <div className="text-white bg-black px-3 rounded font-small">
          <>
            {popupLines.map((line, index) => (
              <Fragment key={index}>
                {line}
                {index < popupLines.length - 1 && <br />}
              </Fragment>
            ))}
          </>
        </div>
      </Popup>
    )
  }, [editDataColumn, getPopupContent, getDisabledColor, isSickoutDisabled])

  const workerIdFormCel = useMemo(() => {
    const workerId = editDataColumn.workerId
    const textColor = getDisabledColor(isSickoutDisabled)
    const handleItemSelect = (item: { key?: string | number; value: string }) => {
      dispatch(
        assignSpotWorkerExistingId(
          workDate,
          statusChangeData.map(data => ({ ...data, workerId: Number(item.key) })),
          saveData
        )
      )
      setSubmitted(true)
    }
    return (
      <div className={`${styles.tableDataWithIcon} ${textColor} column-gap-2`}>
        <i className={`${workerId ? `icf-check ${textColor || 'text-success'}` : 'icf-new text-warning'} font-large`} />
        {workerId ?? (
          <InputSearchBox
            items={availableSpotWorkers.map(w => ({ key: w.id, value: w.name }))}
            placeholder="メンバーIDで検索"
            textColor={textColor}
            onClick={isOpen => !isOpen && dispatch(getAvailableSpotWorkers(workDate))}
            selectedItem={'新規'}
            icon="icf-searchMember"
            onSelect={handleItemSelect}
            containerRef={centerColContainerRef.current || undefined}
            disabled={isEditDisabled}
          />
        )}
        {!workerId && (
          <i
            className="icf-plus font-large text-muted"
            onClick={() => {
              !isEditDisabled && dispatch(putSpotWorkerNew(workDate, statusChangeData, saveData))
            }}
          />
        )}
      </div>
    )
  }, [
    workDate,
    editDataColumn,
    saveData,
    statusChangeData,
    availableSpotWorkers,
    isSickoutDisabled,
    isEditDisabled,
    dispatch,
    getDisabledColor,
    centerColContainerRef,
  ])

  const workspaceFormCel = useMemo(() => {
    const name = editDataColumn.workspace ? editDataColumn.workspace.name : '-'
    const handleItemSelect = (item: { key?: string | number; value: string }) => {
      handleColumnChange(editDataColumn.lineNumber, 'workspace', { id: Number(item.key), name: item.value })
      handleColumnChange(editDataColumn.lineNumber, 'group', null)
      if (isRegistered && Number(item.key) === initDataColumn?.workspace?.id) {
        handleColumnChange(editDataColumn.lineNumber, 'template', initDataColumn?.template || null)
        return
      }
      handleColumnChange(editDataColumn.lineNumber, 'template', null)
    }
    return (
      <InputSearchBox
        disabled={isSickoutDisabled || isEditDisabled}
        items={targetWorkspaces
          .filter(w => w.id !== editDataColumn.workspace?.id)
          .map(w => ({ key: w.id, value: w.name }))}
        selectedItem={name}
        icon="icf-carot_down"
        placeholder="ワークスペース名で検索"
        textColor={getDisabledColor(isSickoutDisabled)}
        onSelect={item => handleItemSelect(item)}
        containerRef={centerColContainerRef.current || undefined}
      />
    )
  }, [
    initDataColumn,
    editDataColumn,
    targetWorkspaces,
    isRegistered,
    isSickoutDisabled,
    isEditDisabled,
    handleColumnChange,
    getDisabledColor,
    centerColContainerRef,
  ])

  const groupFormCel = useMemo(() => {
    const name = editDataColumn.workspace ? editDataColumn.group?.name ?? '未所属' : '-'
    const disabled = isSickoutDisabled || !editDataColumn.workspace
    const handleItemSelect = (item: { key?: string | number; value: string }) => {
      handleColumnChange(
        editDataColumn.lineNumber,
        'group',
        item.key === UNREGISTERED_VALUE_ID ? null : { id: Number(item.key), name: item.value }
      )
    }
    return (
      <InputSearchBox
        items={[{ key: UNREGISTERED_VALUE_ID, value: '未所属' }].concat(
          targetGroups
            .filter(g => g.id !== editDataColumn.group?.id && g.workspaceId === editDataColumn.workspace?.id)
            .map(g => ({ key: g.id, value: g.name }))
        )}
        placeholder="グループ名で検索"
        selectedItem={name}
        textColor={getDisabledColor(disabled)}
        icon="icf-carot_down"
        onSelect={item => handleItemSelect(item)}
        containerRef={centerColContainerRef.current || undefined}
        disabled={disabled || isEditDisabled}
      />
    )
  }, [
    editDataColumn,
    isSickoutDisabled,
    isEditDisabled,
    targetGroups,
    getDisabledColor,
    centerColContainerRef,
    handleColumnChange,
  ])

  const timeSelectBoxCel = useMemo(() => {
    const timeData = [
      { startTime: editDataColumn.workStart1, endTime: editDataColumn.workEnd1 },
      { startTime: editDataColumn.workStart2, endTime: editDataColumn.workEnd2 },
      { startTime: editDataColumn.workStart3, endTime: editDataColumn.workEnd3 },
    ]
    const disabledByShiftStatus =
      isSickoutDisabled ||
      editDataColumn.status === SpotWorkerShiftStatus.UnUpdated ||
      editDataColumn.status === SpotWorkerShiftStatus.ShiftRegistered

    return timeData.map((time, index) => {
      const workStartField = `workStart${index + 1}`
      const workEndField = `workEnd${index + 1}`

      const textColor = getDisabledColor(disabledByShiftStatus)
      return (
        <div key={index} className={`${styles.tableDataWithIcon} ${styles.workTimeSelectBox} ${textColor}`}>
          <SelectTimeForm
            startTime={time.startTime}
            endTime={time.endTime}
            onChangeStartTime={(hour, minute) =>
              handleColumnChange(
                editDataColumn.lineNumber,
                workStartField,
                `${hour}:${minute}` === UNSELECTED_TIME_LABEL ? null : `${hour}:${minute}`
              )
            }
            onChangeEndTime={(hour, minute) =>
              handleColumnChange(
                editDataColumn.lineNumber,
                workEndField,
                `${hour}:${minute}` === UNSELECTED_TIME_LABEL ? null : `${hour}:${minute}`
              )
            }
            isPortal={true}
            portalTarget={centerColContainerRef.current}
            disabled={disabledByShiftStatus || isEditDisabled}
          />
        </div>
      )
    })
  }, [editDataColumn, isSickoutDisabled, isEditDisabled, getDisabledColor, centerColContainerRef, handleColumnChange])

  const editWmsMemberIdFormCel = useMemo(() => {
    return (
      <div className={styles.tableDataWithIcon}>
        <EditTextForm
          value={editDataColumn.wmsMemberId || ''}
          onChange={value => handleColumnChange(editDataColumn.lineNumber, 'wmsMemberId', value === '' ? null : value)}
          defaultValue=""
          disabled={isSickoutDisabled || isEditDisabled}
        />
      </div>
    )
  }, [editDataColumn, isSickoutDisabled, isEditDisabled, handleColumnChange])

  const templateFormCel = useMemo(() => {
    // シフト登録済み、未更新の場合は、ワークスペースが変更されない限りテンプレートを変更できない
    const isRegisteredAndWorkspaceNotEdited =
      isRegistered && editDataColumn.workspace?.id === initDataColumn?.workspace?.id
    // ワークスペースが未設定の場合はテンプレートを選択できない
    const isWorkspaceNull = editDataColumn.workspace === null
    const disabled = isSickoutDisabled || isWorkspaceNull || isRegisteredAndWorkspaceNotEdited
    const displayName = isWorkspaceNull ? '-' : editDataColumn.template?.name || '設定なし'
    return (
      <SelectDropdown
        content={
          <DropdownItem
            disabled={disabled || isEditDisabled}
            className={`${styles.tableDataWithIcon} ${getDisabledColor(disabled)} column-gap-2`}
          >
            {displayName}
            <i className="icf-carot_down font-large" role="button" />
          </DropdownItem>
        }
        items={[{ key: UNREGISTERED_VALUE_ID, value: '指定なし' }].concat(
          targetTemplates
            .filter(t => t.id !== editDataColumn.template?.id && t.workspaceId === editDataColumn.workspace?.id)
            .map(t => ({ key: t.id, value: t.name }))
        )}
        onSelect={item => {
          handleColumnChange(
            editDataColumn.lineNumber,
            'template',
            item.key === UNREGISTERED_VALUE_ID ? null : { id: Number(item.key), name: item.value }
          )
        }}
        isContainer
        containerRef={centerColContainerRef.current || undefined}
        disabled={disabled || isEditDisabled}
      />
    )
  }, [
    initDataColumn,
    editDataColumn,
    isSickoutDisabled,
    isEditDisabled,
    isRegistered,
    targetTemplates,
    handleColumnChange,
    getDisabledColor,
    centerColContainerRef,
  ])

  const skillCel = useMemo(
    () => (
      <div className={styles.tableDataWithIcon}>
        {editDataColumn.skills.map((skill, i) => (
          <div key={i} className="badge rounded-pill text-black bg-light-gray fw-normal font-small me-2">
            <div className={`${getDisabledColor(isSickoutDisabled)} text-truncate`}>{skill.name}</div>
          </div>
        ))}
      </div>
    ),
    [editDataColumn, getDisabledColor, isSickoutDisabled]
  )

  const actionItemCel = useMemo(
    () => (
      <SelectDropdown
        content={<i className="icf-others font-large" role="button" />}
        items={getActionList(editDataColumn.status)}
        onSelect={item => handleActionClick(item.key as ActionType)}
        isContainer
        containerRef={centerColContainerRef.current || undefined}
        disabled={isEditDisabled}
      ></SelectDropdown>
    ),
    [editDataColumn, centerColContainerRef, handleActionClick, isEditDisabled]
  )

  return (
    <tr className={isValid ? 'bg-white' : `${styles.tableInvalidColumn}`}>
      <td className={`${styles.stickyCol} ${styles.left1} ${!isValid && `${styles.tableInvalidColumn}`}`}>
        {workerNameCel}
      </td>
      <td
        className={`${styles.stickyCol} ${styles.left2} ${getStatusColor(editDataColumn.status)} ${!isValid && `${styles.tableInvalidColumn}`}`}
      >
        {shiftStatusCel}
      </td>
      <td>{workerIdFormCel}</td>
      <td>{workspaceFormCel}</td>
      <td>{groupFormCel}</td>
      <td>{timeSelectBoxCel[0]}</td>
      {addWorkTimeRow2 && <td>{timeSelectBoxCel[1]}</td>}
      {addWorkTimeRow3 && <td>{timeSelectBoxCel[2]}</td>}
      <td>{editWmsMemberIdFormCel}</td>
      <td>{templateFormCel}</td>
      <td>{skillCel}</td>
      <td className={`${styles.stickyCol} ${styles.right}`}>{actionItemCel}</td>
    </tr>
  )
}
