import { useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { FormGroup, Input, Label } from 'reactstrap'

import { selectWorkersStatus, exportWorkers } from 'slices/workersSlice'
import { selectWorkspacesStatus } from 'slices/workspacesSlice'

import { CustomModal, ItemEdit } from 'components/common'
import type { SuggestionItem, WorkerType } from 'components/common/types'
import { WorkerTypes, WorkerTypesLabel } from 'components/common/utils'

type Props = {
  isOpen: boolean
  onSuccess: () => void
  onCancel: () => void
}

const UNAFFILIATED_WORKER_ID = -1

const WorkersExportDialog = ({ isOpen, onSuccess, onCancel }: Props) => {
  const [submitted, setSubmitted] = useState(false)
  const [isReplaceAverageProductivity, setReplaceAverageProductivity] = useState(false)
  const [selectedWorkerType, setSelectedWorkerType] = useState<WorkerType>(WorkerTypes.RegularMember)
  const [selectedWorkspaces, setSelectedWorkspaces] = useState<SuggestionItem[]>([])
  const dispatch = useDispatch()
  const { isRequesting, errorMessage } = useSelector(selectWorkersStatus, shallowEqual)
  const { partialWorkspaces } = useSelector(selectWorkspacesStatus, shallowEqual)

  const disabled = useMemo(
    () => selectedWorkerType === WorkerTypes.RegularMember && selectedWorkspaces.length === 0,
    [selectedWorkerType, selectedWorkspaces]
  )

  const handleSaveClick = () => {
    const targetWorkspaceIds = selectedWorkspaces.filter(ws => Number(ws.id) > 0).map(ws => ws.id as number)
    const includeUnaffiliatedWorker = selectedWorkspaces.some(ws => ws.id === UNAFFILIATED_WORKER_ID)
    const data = {
      workerType: selectedWorkerType,
      targetWorkspaceIds,
      replaceAverageProductivity: isReplaceAverageProductivity,
      includeUnaffiliatedWorker: includeUnaffiliatedWorker,
    }
    dispatch(exportWorkers(data))
    setSubmitted(true)
  }

  const handleCancelClick = () => {
    setSelectedWorkspaces([])
    setReplaceAverageProductivity(false)
    setSelectedWorkerType(WorkerTypes.RegularMember)
    onCancel()
  }

  useEffect(() => {
    if (!submitted || isRequesting) {
      return
    }
    if (errorMessage === '') {
      onSuccess()
    }
    setSubmitted(false)
  }, [submitted, isRequesting, errorMessage, onSuccess, dispatch])

  const workspaceItems = useMemo(
    () =>
      partialWorkspaces.map(w => ({ id: w.id, value: w.name })).concat({ id: UNAFFILIATED_WORKER_ID, value: '未所属' }),
    [partialWorkspaces]
  )

  return (
    <CustomModal
      isOpen={isOpen}
      title="メンバーエクスポート"
      approveLabel="CSVエクスポート"
      approveDisabled={disabled}
      onCancel={handleCancelClick}
      onApprove={handleSaveClick}
      submitName="member-export-submit"
    >
      <div className="d-flex flex-column row-gap-4">
        <div>
          <div className="my-2 fw-bold">メンバー属性の選択</div>
          <div className="mb-2">メンバーエクスポートするメンバー属性を選択してください</div>
          <FormGroup check className="d-flex flex-column row-gap-2">
            <Label check>
              <Input
                type="radio"
                name="radio1"
                className="me-2"
                checked={selectedWorkerType === WorkerTypes.RegularMember}
                onClick={() => setSelectedWorkerType(WorkerTypes.RegularMember)}
              />
              {WorkerTypesLabel.find(w => w.key === WorkerTypes.RegularMember)?.value}
            </Label>
            <Label check>
              <Input
                type="radio"
                name="radio1"
                className="me-2"
                onClick={() => setSelectedWorkerType(WorkerTypes.SpotMember)}
              />
              {WorkerTypesLabel.find(w => w.key === WorkerTypes.SpotMember)?.value}
            </Label>
          </FormGroup>
        </div>
        {selectedWorkerType === WorkerTypes.RegularMember && (
          <div>
            <div className="my-2 fw-bold">ワークスペース選択</div>
            <div className="mb-2">メンバーエクスポートするワークスペースを選択してください。</div>
            <ItemEdit
              items={workspaceItems}
              selectedItems={selectedWorkspaces}
              label="ワークスペースを追加"
              itemName="ワークスペース"
              onChange={setSelectedWorkspaces}
            />
          </div>
        )}
        <div className="mb-4">
          <div className="my-2 fw-bold">その他の設定</div>
          <div className="mb-2">作業の人時生産性を過去実績最大30日の平均値と置き換えてエクスポート</div>
          <div className="form-check">
            <Input
              id="contain-productivity-adjustment"
              className="form-check-input"
              checked={isReplaceAverageProductivity}
              type="checkbox"
              onChange={e => setReplaceAverageProductivity(e.target.checked)}
            />
            <Label className="form-check-label" for="contain-productivity-adjustment">
              過去実績の平均値と置き換える
            </Label>
          </div>
        </div>
      </div>
    </CustomModal>
  )
}

export default WorkersExportDialog
