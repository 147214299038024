import { createSlice } from '@reduxjs/toolkit'

import type {
  AvailableSpotWorkersResponse,
  AvailableWorkersProps,
  CreateSpotWorkersResponse,
  PartialWorkspacesAndSubMasters,
  SaveSpotWorkerResponse,
  Skill,
  SpotWorkerDataType,
  SpotWorkerListResponse,
  SpotWorkersSaveDataType,
} from 'api/spot_workers/types'
import { spotWorkerAddResultType } from 'api/spot_workers/types'
import { ENABLE_DIALOG_ERROR_STATUS_CODES, makeErrorMessage, UNAUTHORIZED_ERROR_STATUS_CODE } from 'api/utils'

import * as API from '../api/spot_workers/index'

import * as NetworkErrorDialog from './networkErrorDialogSlice'
import { validateToken } from './sessionSlice'
import * as SessionTimeoutDialog from './sessionTimeoutDialogSlice'
import * as Spinner from './spinnerSlice'
import { commonParams, sleep } from './utils'

import type { CommonParams } from './utils'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { AxiosError } from 'axios'
import type { AppThunk, RootState } from 'store'

type SpotWorkerState = {
  isRequesting: boolean
  errorMessage: string
  errorMessages: string[]
  spotWorkerListResponse?: SpotWorkerListResponse
  spotWorkers: ExtendedSpotWorkerProps[]
  availableSpotWorkers: AvailableWorkersProps[]
  partialWorkspacesAndSubMasters: PartialWorkspacesAndSubMasters[]
  failedColumnNames: string[]
  skills: Skill[]
}

const initialState: SpotWorkerState = {
  isRequesting: false,
  errorMessage: '',
  errorMessages: [],
  spotWorkers: [],
  partialWorkspacesAndSubMasters: [],
  availableSpotWorkers: [],
  skills: [],
  failedColumnNames: [],
  spotWorkerListResponse: undefined,
}

// setEditDataに渡す際、isUpdatedColumnがfalseの場合は修正内容を保持するために、その行のデータを更新しない
export type ExtendedSpotWorkerProps = SpotWorkerDataType & {
  isUpdatedColumn: boolean
}

export type ExtendedSpotWorkersSaveData = SpotWorkersSaveDataType & {
  lineNumber: number
  revision: number
  workerId: number | null
}

export type ShiftChangeProps = {
  lineNumber: number
  revision: number
}

export type AssignProps = {
  lineNumber: number
  revision: number
  workerId: number
}

type ShiftStateChangeAPI = (
  targetParams: CommonParams,
  targetWorkDate: string,
  targetLineNumber: number,
  targetRevision: number
) => Promise<SaveSpotWorkerResponse>

type AssignAPI = (
  targetParams: CommonParams,
  targetWorkDate: string,
  targetLineNumber: number,
  targetRevision: number,
  targetWorkerId: number
) => Promise<SaveSpotWorkerResponse>

type PropsForAPI<T> = T extends ShiftStateChangeAPI ? ShiftChangeProps[] : T extends AssignAPI ? AssignProps[] : never

type serialCallSliceResponse = {
  errorResponses: AxiosError[]
  succeededResponses: SaveSpotWorkerResponse[]
}

export type addSpotWorkerData = {
  workerId: number
  workerName: string
}

export const spotWorkerSlice = createSlice({
  name: 'spotWorker',
  initialState,
  reducers: {
    startRequest: state => {
      state.isRequesting = true
      state.errorMessage = ''
      state.spotWorkers = state.spotWorkers.map(item => ({ ...item, isUpdatedColumn: false }))
    },
    clearErrorMessage: state => {
      state.errorMessage = ''
    },
    clearFailedColumnNames: state => {
      state.failedColumnNames = []
    },
    apiFailure: (state, action: PayloadAction<{ errorMessage: string }>) => {
      state.isRequesting = false
      state.errorMessage = action.payload.errorMessage
    },
    getSpotWorkersSuccess: (state, action: PayloadAction<SpotWorkerListResponse>) => {
      state.isRequesting = false
      state.spotWorkerListResponse = action.payload
      state.partialWorkspacesAndSubMasters = action.payload.partialWorkspacesAndSubMasters
      state.skills = action.payload.skills
      state.spotWorkers = action.payload.spotWorkers.map(item => ({ ...item, isUpdatedColumn: true }))
    },
    getSpotWorkersWithUserAction: (
      state,
      action: PayloadAction<{
        res: SpotWorkerListResponse
        succeededLineNumbers: number[]
        failedLineNumbers: number[]
      }>
    ) => {
      state.isRequesting = false
      // 最新のワークスペースとグループ、スキルを更新する
      state.partialWorkspacesAndSubMasters = action.payload.res.partialWorkspacesAndSubMasters
      state.skills = action.payload.res.skills

      const failedLineNumbers = action.payload.failedLineNumbers
      const updatedLineNumbers = action.payload.succeededLineNumbers.concat(failedLineNumbers)

      // 更新に失敗した行のworkerNameを取得する
      state.failedColumnNames = action.payload.res.spotWorkers
        .filter(item => failedLineNumbers.includes(item.lineNumber))
        .map(item => item.workerName)

      // ユーザーが選択しなかった行のデータを取得する
      const unSelectedColumns = state.spotWorkers.filter(item => !updatedLineNumbers.includes(item.lineNumber))

      // ユーザーが選択しなかった行のデータと、選択した行のデータを結合する
      state.spotWorkers = action.payload.res.spotWorkers
        .filter(item => updatedLineNumbers.includes(item.lineNumber))
        .map(item => ({ ...item, isUpdatedColumn: true }))
        .concat(unSelectedColumns)
    },
    addSpotWorkersSuccess: (
      state,
      action: PayloadAction<{
        res: SpotWorkerListResponse
        succeededLineNumbers: number[]
        failedColumnNames: string[]
      }>
    ) => {
      state.isRequesting = false
      state.failedColumnNames = action.payload.failedColumnNames

      const succeededLineNumbers = action.payload.succeededLineNumbers
      const addedSpotWorkers = action.payload.res.spotWorkers
        .filter(item => succeededLineNumbers.includes(item.lineNumber))
        .map(item => ({ ...item, isUpdatedColumn: true }))

      // 既存の表データに追加したしたデータを結合する
      state.spotWorkers = state.spotWorkers.concat(addedSpotWorkers)
    },
    getAvailableSpotWorkersSuccess: (state, action: PayloadAction<AvailableSpotWorkersResponse>) => {
      state.isRequesting = false
      state.availableSpotWorkers = action.payload.availableWorkers
    },
    requestFinished: state => {
      state.isRequesting = false
    },
  },
})

export const {
  startRequest,
  apiFailure,
  clearErrorMessage,
  clearFailedColumnNames,
  getSpotWorkersSuccess,
  addSpotWorkersSuccess,
  getAvailableSpotWorkersSuccess,
  getSpotWorkersWithUserAction,
  requestFinished,
} = spotWorkerSlice.actions

// 保存時のAPIを直列で呼び出す関数
const serialCallSaveSpotWorker = async (
  data: ExtendedSpotWorkersSaveData[],
  params: CommonParams,
  workDate: string
) => {
  const succeededResponses: SaveSpotWorkerResponse[] = []
  const errorResponses: AxiosError[] = []
  return data
    .reduce((promise, item) => {
      return promise.then(() => {
        const putData = {
          workspaceId: item.workspaceId,
          groupId: item.groupId,
          wmsMemberId: item.wmsMemberId,
          workTemplateId: item.workTemplateId,
          workStart1: item.workStart1,
          workEnd1: item.workEnd1,
          workStart2: item.workStart2,
          workEnd2: item.workEnd2,
          workStart3: item.workStart3,
          workEnd3: item.workEnd3,
        }
        return API.saveSpotWorker(params, putData, workDate, item.lineNumber, item.revision)
          .then((res: SaveSpotWorkerResponse) => {
            succeededResponses.push(res)
          })
          .catch((res: AxiosError) => {
            errorResponses.push(res)
          })
      })
    }, Promise.resolve())
    .then(() => {
      return Promise.resolve({ errorResponses, succeededResponses })
    })
}

// 各行の個別、一括操作のAPIを直列で呼び出す関数
const serialCallShiftChangeAPI = async (
  shiftChangeData: PropsForAPI<ShiftStateChangeAPI>,
  workDate: string,
  params: CommonParams,
  targetApi: ShiftStateChangeAPI
): Promise<serialCallSliceResponse> => {
  const succeededResponses: SaveSpotWorkerResponse[] = []
  const errorResponses: AxiosError[] = []
  return shiftChangeData
    .reduce((promise, item) => {
      return promise.then(() => {
        return targetApi(params, workDate, item.lineNumber, item.revision)
          .then((res: SaveSpotWorkerResponse) => {
            succeededResponses.push(res)
          })
          .catch((res: AxiosError) => {
            errorResponses.push(res)
          })
      })
    }, Promise.resolve())
    .then(() => {
      return Promise.resolve({ errorResponses, succeededResponses })
    })
}

// スポットメンバー追加時のAPIを直列で呼び出す関数
const serialCallAssignSpotWorker = async (data: PropsForAPI<AssignAPI>, workDate: string, params: CommonParams) => {
  const succeededResponses: SaveSpotWorkerResponse[] = []
  const errorResponses: AxiosError[] = []
  return (
    data
      // 画面上ではworkerIdがnullのデータを選択してリクストできないが、ここでは明示的にworkerIdがnullのデータを除外している
      .filter(item => item.workerId !== null)
      .reduce((promise, item) => {
        return promise.then(() => {
          return API.assignSpotWorkerExistingId(params, workDate, item.lineNumber, item.revision, item.workerId!)
            .then((res: SaveSpotWorkerResponse) => {
              succeededResponses.push(res)
            })
            .catch((res: AxiosError) => {
              errorResponses.push(res)
            })
        })
      }, Promise.resolve())
      .then(() => {
        return Promise.resolve({ errorResponses, succeededResponses })
      })
  )
}

// エラーレスポンスからセッションエラー、ネットワークエラーを探す関数
const findCommonError = (errorResponses: AxiosError[]) => {
  return errorResponses.find(errorResponse => {
    const errorCode = makeErrorMessage(errorResponse)
    return ENABLE_DIALOG_ERROR_STATUS_CODES.includes(errorCode)
  })
}

// ユーザーによる個別操作、一括操作、追加操作呼びだし時の処理を行う関数
// saveDataはユーザーによって選択された行のうち変更がされているもののみを含む
// dataはユーザーによって選択された行が含まれる
const processSpotWorker =
  <T extends ShiftStateChangeAPI | AssignAPI>(
    workDate: string,
    data: PropsForAPI<T>,
    saveData: ExtendedSpotWorkersSaveData[],
    targetApi: T,
    serialCallSlice: (
      targetData: PropsForAPI<T>,
      targetWorkDate: string,
      params: CommonParams,
      api: T
    ) => Promise<serialCallSliceResponse>
  ): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startRequest())
    return dispatch(validateToken()).then(valid => {
      if (!valid) {
        return
      }

      // 保存を行う行番号、更新を行う行番号をそれぞれ取得する
      const saveLineNumbers = saveData.map(item => item.lineNumber)
      const updatedLineNumbers = data.map(item => item.lineNumber)

      dispatch(Spinner.start())
      // 登録・更新をする前に一度保存を行う
      return serialCallSaveSpotWorker(saveData, commonParams(getState), workDate)
        .then((res: serialCallSliceResponse) => {
          // セッションエラー、ネットワークエラーがある場合はrejectする
          const commonError = findCommonError(res.errorResponses)
          if (commonError) {
            return Promise.reject({ errorResponse: commonError })
          }

          // 保存に成功した行番号を取得する
          const succeededLineNumberBySave = res.succeededResponses.map(item => item.lineNumber)
          // 保存に失敗した行番号を取得する（putしたデータのうち成功しなかったデータの行番号）
          const failedLineNumberBySave = saveLineNumbers.filter(d => !succeededLineNumberBySave.includes(d))

          // すべての保存が失敗した場合は、更新処理を行わずに行を最新の状態にする
          if (updatedLineNumbers.length === failedLineNumberBySave.length) {
            return Promise.resolve({ errorResponses: res.errorResponses, succeededResponses: [] })
          }

          // 保存後の更新処理を行う行番号は、保存に成功した行番号のみ
          const savedData = data
            .filter(item => !failedLineNumberBySave.includes(item.lineNumber))
            .map(item => {
              // 更新前に保存したデータはrevisionが変更されているため、更新操作前にrevisionを取得する
              const updatedRevision = res.succeededResponses.find(d => d.lineNumber === item.lineNumber)?.revision
              const targetData: ShiftChangeProps | AssignProps = {
                ...item,
                revision: updatedRevision || item.revision,
              }
              return targetData
            })

          // 個別、一括操作を直列で行う
          return serialCallSlice(
            savedData as PropsForAPI<typeof targetApi>,
            workDate,
            commonParams(getState),
            targetApi
          )
        })
        .then((res: serialCallSliceResponse) => {
          // セッションエラー、ネットワークエラーがある場合はrejectする
          const commonError = findCommonError(res.errorResponses)
          if (commonError) {
            return Promise.reject({ errorResponse: commonError })
          }

          // 操作に成功した行番号を取得する（成功した行は必ずnullでない）
          const succeededLineNumbers = res.succeededResponses.map(item => item.lineNumber).filter(n => n !== null)
          // 保存もしくは操作に失敗した行番号を取得する
          const failedLineNumbers = updatedLineNumbers.filter(d => !succeededLineNumbers.includes(d))

          // 失敗成功に関わらず操作を試みた最新の行データを取得する
          return API.getSpotWorkers(commonParams(getState), workDate).then((listResponse: SpotWorkerListResponse) => {
            dispatch(getSpotWorkersWithUserAction({ res: listResponse, succeededLineNumbers, failedLineNumbers }))
          })
        })
        .catch((errorResponse: AxiosError) => {
          const errorCode = makeErrorMessage(errorResponse)
          dispatch(apiFailure({ errorMessage: errorCode }))
          if (errorCode === UNAUTHORIZED_ERROR_STATUS_CODE) {
            dispatch(SessionTimeoutDialog.open())
            return
          }
          dispatch(NetworkErrorDialog.open({ code: errorCode }))
        })
        .finally(() => {
          dispatch(Spinner.stop())
        })
    })
  }

export const getSpotWorkers =
  (workDate: string): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startRequest())
    const valid = await dispatch(validateToken())
    if (!valid) {
      return
    }

    dispatch(Spinner.start())
    API.getSpotWorkers(commonParams(getState), workDate)
      .then((res: SpotWorkerListResponse) => {
        dispatch(getSpotWorkersSuccess(res))
      })
      .catch((res: AxiosError) => {
        const errorCode = makeErrorMessage(res)
        dispatch(apiFailure({ errorMessage: errorCode }))
        if (errorCode === UNAUTHORIZED_ERROR_STATUS_CODE) {
          dispatch(SessionTimeoutDialog.open())
          return
        }
        dispatch(NetworkErrorDialog.open({ code: errorCode }))
      })
      .finally(() => dispatch(Spinner.stop()))
  }

export const addSpotWorkers =
  (workDate: string, workerData: addSpotWorkerData[]): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startRequest())
    const valid = await dispatch(validateToken())
    if (!valid) {
      return
    }

    dispatch(Spinner.start())
    API.addSpotWorker(
      commonParams(getState),
      workerData.map(d => d.workerId),
      workDate
    )
      .then((res: CreateSpotWorkersResponse) => {
        // 追加に成功した行、すでに追加されている行の行番号を取得する
        const succeededLineNumbers = res.info
          .filter(item => item.addResult !== spotWorkerAddResultType.invalid)
          .map(item => item.lineNumber)
        // 追加に失敗した行の名前を取得する（レスポンスには名前がないため、リクエストデータから名前を取得する）
        const failedColumnNames = res.info
          .filter(item => item.addResult === spotWorkerAddResultType.invalid)
          .map(item => workerData.find(d => d.workerId === item.id)?.workerName || '')
          .filter(item => item !== '')

        API.getSpotWorkers(commonParams(getState), workDate).then((listResponse: SpotWorkerListResponse) => {
          dispatch(addSpotWorkersSuccess({ res: listResponse, succeededLineNumbers, failedColumnNames }))
        })
      })
      .catch((res: AxiosError) => {
        const errorCode = makeErrorMessage(res)
        dispatch(apiFailure({ errorMessage: errorCode }))
        if (errorCode === UNAUTHORIZED_ERROR_STATUS_CODE) {
          dispatch(SessionTimeoutDialog.open())
          return
        }
        dispatch(NetworkErrorDialog.open({ code: errorCode }))
      })
      .finally(() => {
        dispatch(requestFinished())
        dispatch(Spinner.stop())
      })
  }

export const spotWorkersImport =
  (workDate: string, data: FormData): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startRequest())
    const valid = await dispatch(validateToken())
    if (!valid) {
      return
    }

    dispatch(Spinner.start())

    try {
      const { requestId } = await API.importSpotWorkers(commonParams(getState), data, workDate)
      if (!requestId) {
        return
      }

      const callGetImportSpotWorkerStatus = async () => {
        const updateStatus = await API.getSpotWorkerImportStatus(commonParams(getState), workDate, requestId)
        if (updateStatus.isCompleted) {
          dispatch(getSpotWorkers(workDate))
          return
        }
        const retryInterval = updateStatus.retryInterval
        retryInterval > 0 && (await sleep(retryInterval))
        await callGetImportSpotWorkerStatus()
      }
      await callGetImportSpotWorkerStatus()
    } catch (err) {
      const errorCode = makeErrorMessage(err as AxiosError)
      dispatch(apiFailure({ errorMessage: errorCode }))
      if (errorCode === UNAUTHORIZED_ERROR_STATUS_CODE) {
        dispatch(SessionTimeoutDialog.open())
        return
      }
      if (errorCode === UNAUTHORIZED_ERROR_STATUS_CODE) {
        dispatch(NetworkErrorDialog.open({ code: errorCode }))
      }
      return
    } finally {
      dispatch(Spinner.stop())
    }
  }

export const getAvailableSpotWorkers =
  (workDate: string): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startRequest())
    const valid = await dispatch(validateToken())
    if (!valid) {
      return
    }

    dispatch(Spinner.start())
    API.getAvailableSpotWorkers(commonParams(getState), workDate)
      .then((res: AvailableSpotWorkersResponse) => {
        dispatch(getAvailableSpotWorkersSuccess(res))
      })
      .catch((res: AxiosError) => {
        const errorCode = makeErrorMessage(res)
        dispatch(apiFailure({ errorMessage: errorCode }))
        if (errorCode === UNAUTHORIZED_ERROR_STATUS_CODE) {
          dispatch(SessionTimeoutDialog.open())
          return
        }
        dispatch(NetworkErrorDialog.open({ code: errorCode }))
      })
      .finally(() => dispatch(Spinner.stop()))
  }

export const saveSpotWorkers =
  (workDate: string, data: ExtendedSpotWorkersSaveData[]): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startRequest())
    const valid = await dispatch(validateToken())
    if (!valid) {
      return
    }

    const saveLineNumbers = data.map(item => item.lineNumber)

    dispatch(Spinner.start())
    await serialCallSaveSpotWorker(data, commonParams(getState), workDate)
      .then((res: serialCallSliceResponse) => {
        // セッションエラー、ネットワークエラーがある場合はrejectする
        const commonError = findCommonError(res.errorResponses)
        if (commonError) {
          return Promise.reject({ errorResponse: commonError })
        }

        // 保存に成功した行番号を取得する（成功した行は必ずnull）
        const succeededLineNumbers = res.succeededResponses.map(item => item.lineNumber).filter(n => n !== null)
        // 保存に失敗した行番号を取得する（postしたデータのうち成功しなかったデータの行番号）
        const failedLineNumbers = saveLineNumbers.filter(d => !succeededLineNumbers.includes(d))

        // 更新後の行を取得する
        return API.getSpotWorkers(commonParams(getState), workDate).then((listResponse: SpotWorkerListResponse) => {
          // 表一覧、更新に成功した行番号、更新に失敗した行番号をactionに渡す
          dispatch(getSpotWorkersWithUserAction({ res: listResponse, succeededLineNumbers, failedLineNumbers }))
        })
      })
      .catch((errorResponse: AxiosError) => {
        const errorCode = makeErrorMessage(errorResponse)
        dispatch(apiFailure({ errorMessage: errorCode }))
        if (errorCode === UNAUTHORIZED_ERROR_STATUS_CODE) {
          dispatch(SessionTimeoutDialog.open())
          return
        }
        dispatch(NetworkErrorDialog.open({ code: errorCode }))
      })
      .finally(() => {
        dispatch(Spinner.stop())
      })
  }

export const deleteSpotWorkers = (
  workDate: string,
  data: ShiftChangeProps[],
  saveData: ExtendedSpotWorkersSaveData[]
): AppThunk => {
  return processSpotWorker<ShiftStateChangeAPI>(
    workDate,
    data,
    saveData,
    API.deleteSpotWorker,
    serialCallShiftChangeAPI
  )
}

export const putSpotWorkersStatusUpdate = (
  workDate: string,
  data: ShiftChangeProps[],
  saveData: ExtendedSpotWorkersSaveData[]
) => {
  return processSpotWorker<ShiftStateChangeAPI>(
    workDate,
    data,
    saveData,
    API.updateSpotWorker,
    serialCallShiftChangeAPI
  )
}

export const putSpotWorkersStatusSickout = (
  workDate: string,
  data: ShiftChangeProps[],
  saveData: ExtendedSpotWorkersSaveData[]
) => {
  return processSpotWorker<ShiftStateChangeAPI>(
    workDate,
    data,
    saveData,
    API.SickoutSpotWorker,
    serialCallShiftChangeAPI
  )
}

export const putSpotWorkerNew = (
  workDate: string,
  data: ShiftChangeProps[],
  saveData: ExtendedSpotWorkersSaveData[]
) => {
  return processSpotWorker<ShiftStateChangeAPI>(
    workDate,
    data,
    saveData,
    API.assignSpotWorkerNewId,
    serialCallShiftChangeAPI
  )
}

export const assignSpotWorkerExistingId = (
  workDate: string,
  data: AssignProps[],
  saveData: ExtendedSpotWorkersSaveData[]
) => {
  return processSpotWorker<AssignAPI>(
    workDate,
    data,
    saveData,
    API.assignSpotWorkerExistingId,
    serialCallAssignSpotWorker
  )
}

export const selectSpotWorkerStatus = (state: RootState) => ({ ...state.spotWorkers })

export default spotWorkerSlice.reducer
