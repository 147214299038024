import type { SpotWorkerAddResult, SpotWorkerShiftStatus } from './constants'

export type SpotWorkerShiftStatusType = (typeof SpotWorkerShiftStatus)[keyof typeof SpotWorkerShiftStatus]

export type EditSpotWorkerColumnDataType = {
  name: string
  shiftStatus: number
  workerId: number | null
  workspace: number | null
  group: number | null
  workStart1: string | null
  workEnd1: string | null
  workStart2: string | null
  workEnd2: string | null
  workStart3: string | null
  workEnd3: string | null
  wmsMemberId: string | null
  template: number | null
  skillIds: number[]
}

export type SpotWorkerColumnDataType = {
  revision: number
  lineNumber: number
  name: string
  status: SpotWorkerShiftStatusType
  deletion: boolean
  sickout: boolean
  workerId: number | null
  workspace: { id: number; name: string } | null
  group: { id: number; name: string } | null
  workStart1: string | null
  workEnd1: string | null
  workStart2: string | null
  workEnd2: string | null
  workStart3: string | null
  workEnd3: string | null
  wmsMemberId: string | null
  template: { id: number; name: string } | null
  skills: { id: number; name: string }[]
}

type PartialGroup = {
  groupId: number
  groupName: string
}

type PartialTemplate = {
  templateId: number
  templateName: string
}

export type Skill = {
  id: number
  name: string
}

export type SpotWorkerDataType = {
  lineNumber: number
  revision: number
  workerId: number | null
  workerName: string
  status: SpotWorkerShiftStatusType
  workspaceId: number | null
  groupId: number | null
  skillIds: number[] | null
  wmsMemberId: string | null
  workTemplateId: number | null
  sickout: boolean
  deletion: boolean
  workStart1: string | null
  workEnd1: string | null
  workStart2: string | null
  workEnd2: string | null
  workStart3: string | null
  workEnd3: string | null
}

export type PartialWorkspacesAndSubMasters = {
  workspaceId: number
  workspaceName: string
  partialGroups: PartialGroup[]
  partialTemplate: PartialTemplate[]
}

export type SpotWorkerListResponse = {
  workDate: string
  currentWorkDate: string
  updatedAt: string
  updatedAtByName: string
  partialWorkspacesAndSubMasters: PartialWorkspacesAndSubMasters[]
  skills: Skill[]
  spotWorkers: SpotWorkerDataType[]
}

type SpotWorkerAddResultType = (typeof SpotWorkerAddResult)[keyof typeof SpotWorkerAddResult]

export const spotWorkerAddResultType: Record<keyof typeof SpotWorkerAddResult, SpotWorkerAddResultType> = {
  ok: 0,
  invalid: 1,
  alreadyExists: 2,
}

type CreateSpotWorkerData = {
  id: number
  lineNumber: number
  addResult: SpotWorkerAddResultType
}

export type CreateSpotWorkersResponse = {
  info: CreateSpotWorkerData[]
}

export type ImportSpotWorkersResponse = {
  requestId: string
}

export type ImportSpotWorkerStatusResponse = {
  retryInterval: number
  isCompleted: boolean
  Errors?: string[]
}

export type AvailableWorkersProps = {
  id: number
  name: string
}

export type AvailableSpotWorkersResponse = {
  availableWorkers: AvailableWorkersProps[]
}

export type SpotWorkersSaveDataType = {
  workspaceId: number | null
  groupId: number | null
  wmsMemberId: string | null
  workTemplateId: number | null
  workStart1: string | null
  workEnd1: string | null
  workStart2: string | null
  workEnd2: string | null
  workStart3: string | null
  workEnd3: string | null
}

export type SaveSpotWorkerResponse = {
  workDate: string | null
  lineNumber: number | null
  revision: number | null
  workerId: number | null
  updatedAt: string | null
  updatedByName: string | null
  Errors: string[] | null
}
