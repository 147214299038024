import dayjs from 'dayjs'
import { isEmpty, isEqual } from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { Button, Card, DropdownItem } from 'reactstrap'

import { SpotWorkerShiftStatus } from 'api/spot_workers/constants'

import { showError, showSuccess } from 'slices/notificationSlice'
import {
  deleteSpotWorkers,
  getAvailableSpotWorkers,
  getSpotWorkers,
  putSpotWorkerNew,
  putSpotWorkersStatusSickout,
  putSpotWorkersStatusUpdate,
  selectSpotWorkerStatus,
  saveSpotWorkers,
} from 'slices/spotWorkerSlice'
import { selectTenantsStatus } from 'slices/tenantsSlice'

import {
  CardSubmitFooter,
  CustomButton,
  DateChangeButton,
  DropdownList,
  FilteringButton,
  FilteringInputField,
  NavMenu,
} from 'components/common'
import SpotWorkerTable from 'components/common/SpotWorkerTable/SpotWorkerTable'

import useDateQuery from 'hooks/useDateQuery'
import useSpotWorker from 'hooks/useSpotWorker'
import useSpotWorkerCsv from 'hooks/useSpotWorkerCsv'

import placeholder from 'images/allEmpty.svg'

import { SpotWorkerCreateDialog } from './SpotWorkerCreateDialog'
import { SpotWorkerImportDialog } from './SpotWorkerImportDialog '
import { SpotWorkerRequestFailDialog } from './SpotWorkerRequestFailDialog'

import styles from './SpotWorker.module.scss'

const SpotWorker = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const workDate = useDateQuery()
  const { exportSpotWorkers } = useSpotWorkerCsv()
  const { pathname } = useLocation()
  const {
    checkSpotWorkerShiftStatusType,
    checkIsDateInEditableRange,
    initTableData,
    filteredTableData,
    tableData,
    setTableData,
    targetWorkspaces,
    selectedStatus,
    selectedWorkspaces,
    selectedSkills,
    setSelectedStatus,
    setSelectedWorkspaces,
    setSelectedSkills,
    setFilterWorkerName,
    filterStatus,
    filterWorkspaces,
    filterSkills,
    filterWorkerName,
  } = useSpotWorker()

  const { tenant } = useSelector(selectTenantsStatus, shallowEqual)
  const { spotWorkerListResponse, isRequesting, errorMessage, failedColumnNames } = useSelector(
    selectSpotWorkerStatus,
    shallowEqual
  )
  const [isImportDialogOpen, setIsImportDialogOpen] = useState<boolean>(false)
  const [isAdditionDialogOpen, setIsAdditionDialogOpen] = useState<boolean>(false)
  const [isSelectWorkspaceOpen, setIsSelectWorkspaceOpen] = useState<boolean>(false)
  const [isHintOpen, setHintOpen] = useState<boolean>(true)
  const [selectedUser, setSelectedUser] = useState<number[]>([])
  const [submitted, setSubmitted] = useState<boolean>(false)
  const [isValidAllColumns, setIsValidAllColumns] = useState<boolean>(true)

  useEffect(() => {
    dispatch(getSpotWorkers(workDate))
  }, [workDate, dispatch])

  useEffect(() => {
    if (submitted) {
      setSelectedUser([])
    }
  }, [submitted])

  useEffect(() => {
    if (!submitted || isRequesting) {
      return
    }
    if (errorMessage === '' && isEmpty(failedColumnNames)) {
      dispatch(showSuccess())
    }
    // エラーはダイアログで表示する
    setSubmitted(false)
  }, [submitted, isRequesting, errorMessage, dispatch, workDate, failedColumnNames])

  const checkDataWithinValidRange = useCallback((newDate: Date) => {
    const targetDate = newDate.getTime()
    const date425DaysAgo = new Date().getTime() - 425 * 24 * 60 * 60 * 1000
    const data45DaysFuture = new Date().getTime() + 45 * 24 * 60 * 60 * 1000
    return targetDate > date425DaysAgo && targetDate < data45DaysFuture
  }, [])

  const handleDetailClick = () => window.open('https://help.smileboard.jp/day-laborer-skill-management', '_blank')

  const handleWorkspaceSelect = useCallback(
    (workspace: { id: number; name: string }) => {
      setTableData(
        filteredTableData
          .filter(data => data.lineNumber)
          .map(data => {
            if (data.status === SpotWorkerShiftStatus.Sickout || !selectedUser.includes(data.lineNumber)) {
              return data
            }
            return {
              ...data,
              workspace,
            }
          })
      )
    },
    [filteredTableData, selectedUser, setTableData]
  )

  // 保存ボタン押下時のデータ
  const targetSaveColumns = useMemo(() => {
    return filteredTableData
      .filter(
        data =>
          !isEqual(
            initTableData.find(d => d.lineNumber === data.lineNumber),
            data
          ) && data.status !== SpotWorkerShiftStatus.Sickout
      )
      .map(data => ({
        revision: data.revision,
        lineNumber: data.lineNumber,
        workerId: data.workerId,
        workspaceId: data.workspace?.id || null,
        groupId: data.group?.id || null,
        wmsMemberId: data.wmsMemberId,
        workTemplateId: data.template?.id || null,
        workStart1: data.workStart1,
        workEnd1: data.workEnd1,
        workStart2: data.workStart2,
        workEnd2: data.workEnd2,
        workStart3: data.workStart3,
        workEnd3: data.workEnd3,
      }))
  }, [filteredTableData, initTableData])

  // ユーザーによって個別、一括操作されるデータ
  const selectedColumns = useMemo(() => {
    return filteredTableData.filter(data => selectedUser.includes(data.lineNumber))
  }, [filteredTableData, selectedUser])

  // 個別、一括操作押下時の保存データ
  const selectedSaveColumns = useMemo(
    () => targetSaveColumns.filter(data => selectedUser.includes(data.lineNumber)),
    [targetSaveColumns, selectedUser]
  )

  // 登録・更新押下時のデータ
  const targetUpdateColumns = useMemo(() => {
    return selectedColumns
      .filter(
        data =>
          data.status === SpotWorkerShiftStatus.UnUpdated ||
          data.status === SpotWorkerShiftStatus.ShiftRegistered ||
          data.status === SpotWorkerShiftStatus.ShiftUnregistered
      )
      .map(data => ({
        lineNumber: data.lineNumber,
        revision: data.revision,
      }))
  }, [selectedColumns])

  // 欠勤押下時のデータ
  const targetSickoutColumns = useMemo(() => {
    return selectedColumns
      .filter(
        data =>
          data.status !== SpotWorkerShiftStatus.Sickout &&
          data.status !== SpotWorkerShiftStatus.Insufficient &&
          data.status !== SpotWorkerShiftStatus.InsufficientWithoutId &&
          data.status !== SpotWorkerShiftStatus.ShiftUnregistered
      )
      .map(data => ({
        lineNumber: data.lineNumber,
        revision: data.revision,
      }))
  }, [selectedColumns])

  // メンバー追加押下時のデータ
  const targetNewMemberIdColumns = useMemo(() => {
    return selectedColumns
      .filter(data => data.status === SpotWorkerShiftStatus.Insufficient)
      .map(data => ({
        lineNumber: data.lineNumber,
        revision: data.revision,
        workerId: data.workerId,
      }))
  }, [selectedColumns])

  // 削除押下時のデータ
  const targetDeleteColumns = useMemo(() => {
    return selectedColumns.map(data => ({
      lineNumber: data.lineNumber,
      revision: data.revision,
    }))
  }, [selectedColumns])

  const spotWorkerCountStatement = useMemo(() => {
    const totalCount = filteredTableData.length
    const sickoutCount = filteredTableData.filter(data => data.status === SpotWorkerShiftStatus.Sickout).length
    return `${totalCount}人（うち欠勤：${sickoutCount}人）`
  }, [filteredTableData])

  const disabled = useMemo(
    () => isEmpty(selectedUser) || !checkIsDateInEditableRange(workDate),
    [selectedUser, workDate, checkIsDateInEditableRange]
  )
  const disabledUpdateColumns = useMemo(
    () =>
      selectedColumns.some(
        data =>
          data.status !== SpotWorkerShiftStatus.UnUpdated && data.status !== SpotWorkerShiftStatus.ShiftUnregistered
      ),
    [selectedColumns]
  )

  const disabledSickoutColumns = useMemo(
    () =>
      selectedColumns.some(
        data => data.status !== SpotWorkerShiftStatus.ShiftRegistered && data.status !== SpotWorkerShiftStatus.UnUpdated
      ),
    [selectedColumns]
  )

  const disabledNewColumns = useMemo(
    () => selectedColumns.some(data => data.status !== SpotWorkerShiftStatus.Insufficient),
    [selectedColumns]
  )

  const handleDateChange = useCallback(
    (newDate: string) => {
      if (tenant && dayjs(newDate).isBefore(tenant.createdAt, 'day')) {
        dispatch(showError({ errorMessage: 'テナント作成日以前の日付は選択できません' }))
        return
      }
      navigate(`${pathname}?date=${newDate}`)
    },
    [dispatch, navigate, pathname, tenant]
  )

  const handleColumnChange = useCallback(
    (lineNumber: number, key: string, value: { id: number; name: string } | string | null) => {
      setTableData(prev =>
        prev.map(column => {
          if (column.lineNumber === lineNumber) {
            return {
              ...column,
              [key]: value,
            }
          }
          return column
        })
      )
    },
    [setTableData]
  )

  return (
    <NavMenu>
      <div className={`${styles.container} mt-3 mx-3 ${isHintOpen ? '' : styles.hidden}`}>
        <div className="font-x-large fw-bold align-self-center mb-3">スポットメンバー管理</div>
        <div className={styles.help}>
          <div className="d-flex align-items-center">
            <i className={`icf-info text-secondary font-middle mx-3`} role="none" />
            <div>スポットメンバー管理はシフトにスポットメンバーを登録するための下書き機能です。</div>
          </div>
          <Button color="link" className="shadow-none text-dark" onClick={() => setHintOpen(false)}>
            この表示を閉じる
          </Button>
        </div>
        <Card className={`position-sticky ${styles.list} mt-3`}>
          <div className="m-3">
            <div className="d-flex justify-content-between mb-3">
              <div className="d-flex justify-content align-items-center column-gap-2">
                <DateChangeButton
                  date={workDate}
                  popupPosition="bottom right"
                  filterDate={newDate => checkDataWithinValidRange(newDate)}
                  onChange={newDate => handleDateChange(dayjs(newDate).format('YYYY-MM-DD'))}
                  isWorkPlanView
                />
                <div className="font-x-large fw-bold">のスポットメンバー</div>
                <Button
                  color="link"
                  className="shadow-none"
                  disabled={!checkDataWithinValidRange(dayjs(workDate).subtract(1, 'day').toDate())}
                  onClick={() => handleDateChange(dayjs(workDate).subtract(1, 'day').format('YYYY-MM-DD'))}
                >
                  前の日
                </Button>
                <Button
                  color="link"
                  className="shadow-none"
                  disabled={!checkDataWithinValidRange(dayjs(workDate).add(1, 'day').toDate())}
                  onClick={() => handleDateChange(dayjs(workDate).add(1, 'day').format('YYYY-MM-DD'))}
                >
                  次の日
                </Button>
              </div>
              <div className="d-flex justify-content column-gap-2">
                <CustomButton
                  outline
                  onClick={() => setIsImportDialogOpen(true)}
                  disabled={!checkIsDateInEditableRange(workDate)}
                >
                  インポート
                </CustomButton>
                <CustomButton
                  outline
                  onClick={() => {
                    exportSpotWorkers(tableData)
                  }}
                >
                  エクスポート
                </CustomButton>
                <CustomButton
                  icon="plus"
                  outline
                  onClick={() => {
                    setIsAdditionDialogOpen(true)
                    dispatch(getAvailableSpotWorkers(workDate))
                  }}
                  disabled={!checkIsDateInEditableRange(workDate)}
                >
                  既存のスポットメンバーから追加
                </CustomButton>
              </div>
            </div>
            <div className="border-bottom mb-3"></div>
            {tableData.length > 0 ? (
              <div>
                <div className="d-flex justify-content align-items-center mb-3 column-gap-2">
                  <FilteringInputField
                    onChange={setFilterWorkerName}
                    placeholder="メンバー名で検索"
                    value={filterWorkerName}
                  />
                  <FilteringButton
                    items={filterWorkspaces}
                    onChange={setSelectedWorkspaces}
                    value={selectedWorkspaces}
                    label="配属先ワークスペースで絞り込み"
                    isEnableUnCheckAll={true}
                  />
                  <FilteringButton
                    items={filterSkills}
                    onChange={setSelectedSkills}
                    value={selectedSkills}
                    label="スキルで絞り込み"
                    isEnableUnCheckAll={true}
                  />
                  <FilteringButton
                    items={filterStatus}
                    onChange={values => setSelectedStatus(values.filter(checkSpotWorkerShiftStatusType))}
                    value={selectedStatus}
                    label="その他"
                    isEnableUnCheckAll={true}
                  />
                </div>
                <div className="d-flex justify-content-between">
                  <div className="d-flex justify-content align-items-center column-gap-2">
                    <CustomButton
                      outline
                      onClick={() => {
                        dispatch(putSpotWorkersStatusUpdate(workDate, targetUpdateColumns, selectedSaveColumns))
                        setSubmitted(true)
                      }}
                      disabled={disabled || disabledUpdateColumns}
                    >
                      登録・更新
                    </CustomButton>
                    <CustomButton
                      outline
                      onClick={() => {
                        dispatch(putSpotWorkersStatusSickout(workDate, targetSickoutColumns, selectedSaveColumns))
                        setSubmitted(true)
                      }}
                      disabled={disabled || disabledSickoutColumns}
                    >
                      欠勤
                    </CustomButton>
                    <CustomButton
                      outline
                      onClick={() => {
                        dispatch(putSpotWorkerNew(workDate, targetNewMemberIdColumns, selectedSaveColumns))
                        setSubmitted(true)
                      }}
                      disabled={disabled || disabledNewColumns}
                    >
                      メンバー登録
                    </CustomButton>
                    <DropdownList
                      open={isSelectWorkspaceOpen}
                      setOpen={() => {
                        setIsSelectWorkspaceOpen(!isSelectWorkspaceOpen && !disabled)
                      }}
                      content={
                        <CustomButton outline disabled={disabled}>
                          配属先ワークスペース選択
                        </CustomButton>
                      }
                    >
                      {targetWorkspaces.map(workspace => {
                        return (
                          <DropdownItem key={workspace.id} onClick={() => handleWorkspaceSelect(workspace)}>
                            {workspace.name}
                          </DropdownItem>
                        )
                      })}
                    </DropdownList>
                    <Button
                      color="danger"
                      outline
                      onClick={() => {
                        dispatch(deleteSpotWorkers(workDate, targetDeleteColumns, selectedSaveColumns))
                        setSubmitted(true)
                      }}
                      disabled={disabled}
                    >
                      削除
                    </Button>
                  </div>
                  <div className="font-x-small text-muted d-flex align-items-center">{`${spotWorkerCountStatement}`}</div>
                </div>
                <div className={`${styles.spotWorkerTable}`}>
                  <SpotWorkerTable
                    handleColumnChange={handleColumnChange}
                    initData={initTableData}
                    editData={filteredTableData}
                    selectedUser={selectedUser}
                    onSelectUser={users => setSelectedUser(users)}
                    onChangeTableData={isValid => setIsValidAllColumns(isValid)}
                  />
                </div>
              </div>
            ) : (
              <div className={styles.emptyView}>
                <img className={styles.placeholderImage} src={placeholder} alt="" />
                <div className="font-middle fw-bold">スポットメンバーがまだ登録されていません</div>
                <div>まずは最初のスポットメンバーを登録してみましょう。</div>
                <Button size="sm" outline onClick={handleDetailClick}>
                  スポットメンバー管理についてもっと詳しく
                </Button>
              </div>
            )}
            {tableData.length > 0 && (
              <div className="flex-grow-0">
                <CardSubmitFooter
                  onCancel={() => setTableData(initTableData)}
                  onSubmit={() => {
                    dispatch(saveSpotWorkers(workDate, targetSaveColumns))
                    setSubmitted(true)
                  }}
                  updatedBy={spotWorkerListResponse?.updatedAtByName || ''}
                  updatedAt={spotWorkerListResponse?.updatedAt || ''}
                  submitDisabled={
                    isEmpty(targetSaveColumns) || !isValidAllColumns || !checkIsDateInEditableRange(workDate)
                  }
                />
              </div>
            )}
          </div>
        </Card>
        <SpotWorkerImportDialog
          isOpen={isImportDialogOpen}
          workDate={workDate}
          onCancel={() => setIsImportDialogOpen(false)}
          onSuccess={() => setIsImportDialogOpen(false)}
        />
        <SpotWorkerCreateDialog
          isOpen={isAdditionDialogOpen}
          workDate={workDate}
          onSuccess={() => setIsAdditionDialogOpen(false)}
          onCancel={() => setIsAdditionDialogOpen(false)}
        />
        <SpotWorkerRequestFailDialog />
      </div>
    </NavMenu>
  )
}

export default SpotWorker
