import axios from 'axios'

import type { CommonParams } from 'slices/utils'

import type {
  AvailableSpotWorkersResponse,
  CreateSpotWorkersResponse,
  ImportSpotWorkersResponse,
  ImportSpotWorkerStatusResponse,
  SaveSpotWorkerResponse,
  SpotWorkerListResponse,
  SpotWorkersSaveDataType,
} from './types'

export const getSpotWorkers = (params: CommonParams, workDate: string): Promise<SpotWorkerListResponse> => {
  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v2/tenants/${params.tenantId}/spot-workers/work-date/${workDate}`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .get(url, { headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

export const addSpotWorker = (
  params: CommonParams,
  workerIds: number[],
  workDate: string
): Promise<CreateSpotWorkersResponse> => {
  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v2/tenants/${params.tenantId}/spot-workers/work-date/${workDate}`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .post(url, { workerIds }, { headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}
export const importSpotWorkers = (
  params: CommonParams,
  data: FormData,
  workDate: string
): Promise<ImportSpotWorkersResponse> => {
  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v2/tenants/${params.tenantId}/spot-workers/work-date/${workDate}/import/async`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
      'Content-Type': 'multipart/form-data',
    }
    axios
      .post(url, data, { headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

export const getSpotWorkerImportStatus = (
  params: CommonParams,
  workDate: string,
  requestId: string
): Promise<ImportSpotWorkerStatusResponse> => {
  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v2/tenants/${params.tenantId}/spot-workers/work-date/${workDate}/import/${requestId}/import-status`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .get(url, { headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

export const getAvailableSpotWorkers = (
  params: CommonParams,
  workDate: string
): Promise<AvailableSpotWorkersResponse> => {
  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v2/tenants/${params.tenantId}/spot-workers/work-date/${workDate}/available-workers`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .get(url, { headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

export const saveSpotWorker = (
  params: CommonParams,
  data: SpotWorkersSaveDataType,
  workDate: string,
  lineNumber: number,
  revision: number
): Promise<SaveSpotWorkerResponse> => {
  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v2/tenants/${params.tenantId}/spot-workers/work-date/${workDate}/line-number/${lineNumber}/revisions/${revision}`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .put(url, data, { headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

export const deleteSpotWorker = (
  params: CommonParams,
  workDate: string,
  lineNumber: number,
  revision: number
): Promise<SaveSpotWorkerResponse> => {
  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v2/tenants/${params.tenantId}/spot-workers/work-date/${workDate}/line-number/${lineNumber}/revisions/${revision}`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .delete(url, { headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

export const updateSpotWorker = (
  params: CommonParams,
  workDate: string,
  lineNumber: number,
  revision: number
): Promise<SaveSpotWorkerResponse> => {
  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v2/tenants/${params.tenantId}/spot-workers/work-date/${workDate}/line-number/${lineNumber}/revisions/${revision}/line-update`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .put(url, {}, { headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

export const SickoutSpotWorker = (
  params: CommonParams,
  workDate: string,
  lineNumber: number,
  revision: number
): Promise<SaveSpotWorkerResponse> => {
  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v2/tenants/${params.tenantId}/spot-workers/work-date/${workDate}/line-number/${lineNumber}/revisions/${revision}/sickout`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .put(url, {}, { headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

export const assignSpotWorkerNewId = (
  params: CommonParams,
  workDate: string,
  lineNumber: number,
  revision: number
): Promise<SaveSpotWorkerResponse> => {
  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v2/tenants/${params.tenantId}/spot-workers/work-date/${workDate}/line-number/${lineNumber}/revisions/${revision}/new`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .put(url, {}, { headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

export const assignSpotWorkerExistingId = (
  params: CommonParams,
  workDate: string,
  lineNumber: number,
  revision: number,
  workerId: number
): Promise<SaveSpotWorkerResponse> => {
  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v2/tenants/${params.tenantId}/spot-workers/work-date/${workDate}/line-number/${lineNumber}/revisions/${revision}/assign`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .put(url, { workerId }, { headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}
