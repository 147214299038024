import dayjs from 'dayjs'
import _ from 'lodash'
import * as React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useNavigate, Link } from 'react-router-dom'
import { FormGroup, Label, Col } from 'reactstrap'

import { CONFLICT_ERROR_STATUS_CODE, ENABLE_DIALOG_ERROR_STATUS_CODES } from 'api/utils'
import type { CreateWorkerEditDataType } from 'api/workers'

import { getGroupList, selectGroupsStatus } from 'slices/groupsSlice'
import { getOfficialDutiesList, selectOfficialDutiesStatus } from 'slices/officialDutiesSlice'
import { getSkillList, selectSkillsStatus } from 'slices/skillsSlice'
import { createWorker, selectWorkersStatus } from 'slices/workersSlice'
import { selectWorkspacesStatus, getWorkspaceList } from 'slices/workspacesSlice'

import {
  CheckBoxFormat,
  InputFormat,
  Notification,
  SelectBoxFormat,
  DatePicker,
  SubmitFooter,
  ItemEdit,
  RadioButtonFormat,
} from 'components/common'
import * as Rules from 'components/common/FormFormat/ValidationRules'
import type { SuggestionItem, WorkerType } from 'components/common/types'
import { ColumnSizes, WorkerTypes, WorkerTypesLabel } from 'components/common/utils'

import useWorker from 'hooks/useWorker'

import HourlyProductivitiesInput from './PerformanceIndicesInput'
import { OFFICIAL_DUTY_BLANK_ID } from './WorkerList'

import styles from './WorkerCreate.module.scss'

const WorkerCreate: React.FC = () => {
  const [groupSelectItems, setGroupSelectItems] = React.useState<Array<{ key: number; value: string }>>([])
  const [modalErrorMessage, setModalErrorMessage] = React.useState<string | undefined>(undefined)
  const [submitted, setSubmitted] = React.useState(false)
  const [isRegularMember, setIsRegularMember] = React.useState(true)
  const navigate = useNavigate()

  const { partialWorkspaces } = useSelector(selectWorkspacesStatus, shallowEqual)
  const { groups } = useSelector(selectGroupsStatus, shallowEqual)
  const { skills } = useSelector(selectSkillsStatus, shallowEqual)
  const { partialOfficialDutiesList } = useSelector(selectOfficialDutiesStatus, shallowEqual)

  const dispatch = useDispatch()

  const { showHourlyProductivities, disabled, editData, setEditData, setNameValidity, setHourlyProductivities } =
    useWorker()

  React.useEffect(() => {
    dispatch(getWorkspaceList())
    dispatch(getSkillList())
    dispatch(getOfficialDutiesList())
  }, [dispatch])

  const officialDutiesSelectItems = React.useMemo(() => {
    const items = partialOfficialDutiesList.map(officialDuties => ({
      key: officialDuties.id,
      value: officialDuties.name ?? '',
    }))

    items.unshift({ key: OFFICIAL_DUTY_BLANK_ID, value: '未設定' })
    return items
  }, [partialOfficialDutiesList])

  React.useEffect(() => setGroupSelectItems(groups.map(group => ({ key: group.id, value: group.name }))), [groups])

  React.useEffect(() => {
    if (editData.workspace) {
      dispatch(getGroupList(editData.workspace.key))
    } else {
      setGroupSelectItems([])
    }
  }, [dispatch, editData.workspace])

  const workspaceSelectItems = React.useMemo(
    () =>
      partialWorkspaces.map(w => ({
        key: w.id,
        value: w.name,
      })),
    [partialWorkspaces]
  )

  const onSubmit = () => {
    setSubmitted(true)

    const data: CreateWorkerEditDataType = {
      name: editData.name || '',
      officialDutyId:
        editData.officialDuties?.key === OFFICIAL_DUTY_BLANK_ID ? null : editData.officialDuties?.key ?? null,
      wmsMemberId: editData.wmsMemberId || '',
      workerType: editData.workerTypes?.key || WorkerTypes.RegularMember,
      workspaceId: editData.workspace?.key || null,
      groupId: editData.groupId || null,
      groupLeader: editData.groupLeader,
      hiredAt: editData.hiredAt || null,
      skillIds: editData.skills.map(skill => skill.id),
      hourlyProductivities: editData.hourlyProductivities.map(productivity => _.omit(productivity, 'average')),
    }
    dispatch(createWorker(data))
  }

  const { isRequesting, errorMessage } = useSelector(selectWorkersStatus, shallowEqual)
  React.useEffect(() => {
    if (!submitted || isRequesting) {
      return
    }
    if (errorMessage === '') {
      navigate('/workers')
    } else {
      if (errorMessage === CONFLICT_ERROR_STATUS_CODE) {
        setModalErrorMessage('ID・識別番号が重複しています。')
      } else if (!ENABLE_DIALOG_ERROR_STATUS_CODES.includes(errorMessage)) {
        // ENABLE_DIALOG_ERROR_STATUS_CODESのときにはエラーダイアログが出るのでNotificationは出さない
        setModalErrorMessage('保存できませんでした。')
      }
    }
    setSubmitted(false)
  }, [submitted, isRequesting, errorMessage, dispatch, navigate])

  const handleSkillEdit = (items: SuggestionItem[]) => {
    const skillData = skills.filter(s => items.some(i => i.id === s.id))
    setEditData({ ...editData, skills: skillData })
  }

  const unchanged = React.useMemo(() => {
    if (isRegularMember) {
      return !editData.name || !editData.wmsMemberId
    }
    return !editData.name
  }, [editData, isRegularMember])

  return (
    <>
      <div className={styles.container}>
        <div className="sticky-top">
          <div className="font-x-large fw-bold text-center border-bottom py-3 bg-white">メンバーの登録</div>
          <Notification
            errorMessage={modalErrorMessage}
            error={!!modalErrorMessage}
            hide={() => setModalErrorMessage(undefined)}
          />
        </div>
        <div className="w-50 mx-auto mt-3 pb-3">
          <div className="d-flex justify-content-between py-1">
            <div className="font-middle fw-bold">メンバー詳細</div>
            <small>※必須項目</small>
          </div>
          <InputFormat
            label="名前※"
            placeholder="メンバー名を入力"
            value={editData.name}
            size={ColumnSizes.middle}
            maxLength={100}
            onChange={value => setEditData({ ...editData, name: value.trim() })}
            validations={[Rules.Required]}
            onValidate={setNameValidity}
            className="mb-3"
          />

          <RadioButtonFormat
            label="メンバー属性"
            value={editData.workerTypes?.key.toString()}
            size={ColumnSizes.middle}
            items={WorkerTypesLabel.map(item => ({
              key: item.key as number, // RadioButtonFormatは汎用的に構えたいので、number型に変換
              value: item.value,
              description: item.description,
            }))}
            onChange={item => {
              setEditData({ ...editData, workerTypes: { key: item.key as WorkerType, value: item.value } })
              setIsRegularMember(item.key === WorkerTypes.RegularMember)
            }}
            className="mb-3"
          />

          {isRegularMember && (
            <InputFormat
              label="識別子※"
              placeholder="識別子を入力"
              formText="WMS等の外部システムでメンバー毎に設定されている識別子を入力してください。"
              value={editData.wmsMemberId ?? undefined}
              size={ColumnSizes.middle}
              maxLength={100}
              onChange={value => setEditData({ ...editData, wmsMemberId: value.trim() })}
              validations={[Rules.Required]}
              className="mb-3"
            />
          )}

          {isRegularMember && (
            <FormGroup row>
              <Label for="hiredAt" md={4}>
                入社日
              </Label>
              <Col md={4} className="align-self-center">
                <DatePicker
                  value={editData.hiredAt}
                  placeholder="入社日を選択"
                  onChange={date => setEditData({ ...editData, hiredAt: dayjs(date).format('YYYY-MM-DD') })}
                />
              </Col>
            </FormGroup>
          )}

          {!_.isEmpty(partialOfficialDutiesList) && (
            <SelectBoxFormat
              label="職掌"
              placeholder="職掌を選択"
              formText="選択された職掌の平均時給を用いて費用を計算します。"
              value={(editData.officialDuties?.key || OFFICIAL_DUTY_BLANK_ID).toString()}
              size={ColumnSizes.middle}
              items={officialDutiesSelectItems}
              onChange={e =>
                setEditData({ ...editData, officialDuties: officialDutiesSelectItems.find(item => item.key === e.key) })
              }
              className="mb-3"
            />
          )}

          {isRegularMember && (
            <>
              <SelectBoxFormat
                label="所属ワークスペース"
                placeholder="ワークスペースを選択"
                formText="メンバーに予定を設定するためにはワークスペースに所属させてください。"
                value={editData.workspace?.key.toString()}
                size={ColumnSizes.middle}
                items={workspaceSelectItems}
                onChange={e => {
                  const workspace = workspaceSelectItems.find(w => w.key.toString() === e.key?.toString())
                  setEditData({ ...editData, workspace, groupId: undefined, groupLeader: false })
                }}
                className="mb-3"
              />
              {groupSelectItems.length > 0 && (
                <>
                  <SelectBoxFormat
                    label="所属グループ"
                    placeholder="グループを選択"
                    value={groups.find(g => g.id === editData.groupId)?.id.toString()}
                    size={ColumnSizes.middle}
                    items={groupSelectItems}
                    onChange={e => {
                      const group = groupSelectItems.find(g => g.key.toString() === e.key?.toString())
                      setEditData({ ...editData, groupId: group?.key })
                    }}
                    className="mb-3"
                  />
                  {editData.groupId && (
                    <CheckBoxFormat
                      label="グループリーダー"
                      checkboxLabel="リーダー"
                      checked={editData.groupLeader}
                      onChange={e => setEditData({ ...editData, groupLeader: e.target.checked })}
                    />
                  )}
                </>
              )}
            </>
          )}

          <div className="font-middle fw-bold pt-5">スキル設定</div>
          <div className="py-2">
            メンバーにスキルを設定する事ができます。新しいスキルは
            <Link className="text-decoration-none" to="/skills">
              スキル管理
            </Link>
            で登録してください。
          </div>
          <ItemEdit
            items={skills.map(s => ({ id: s.id, value: s.name }))}
            selectedItems={editData.skills.map(s => ({ id: s.id, value: s.name }))}
            label="メンバーにスキルを追加"
            itemName="スキル"
            onChange={handleSkillEdit}
          />
          {showHourlyProductivities && (
            <div>
              <div className="font-middle fw-bold pt-5">人時生産性設定</div>
              <div className="py-2">
                全てのワークスペースに登録されている作業に対する人時生産性を設定する事ができます。所属ワークスペース以外の作業についても人時生産性を設定しておく事でワークスペース間を移動して作業した際にも人時生産性を計算する事ができます。
              </div>
              <HourlyProductivitiesInput
                hourlyProductivities={editData.hourlyProductivities}
                onChange={setHourlyProductivities}
              />
            </div>
          )}
        </div>
      </div>
      <SubmitFooter onCancel={() => navigate(-1)} onSubmit={onSubmit} submitDisabled={disabled || unchanged} />
    </>
  )
}

export default WorkerCreate
