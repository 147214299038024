import _ from 'lodash'
import * as React from 'react'
import { Input, Label } from 'reactstrap'

import type { SpotWorkerColumnDataType } from 'api/spot_workers/types'

import useSpotWorker from 'hooks/useSpotWorker'

import { SpotWorkerTableColumn } from './SpotWorkerTableColumn'

import styles from './SpotWorkerTable.module.scss'

type Props = {
  initData: SpotWorkerColumnDataType[]
  editData: SpotWorkerColumnDataType[]
  selectedUser: number[]
  onSelectUser: (newSelectedUser: number[]) => void
  handleColumnChange: (lineNumber: number, key: string, value: { id: number; name: string } | string | null) => void
  onChangeTableData: (isValid: boolean) => void
}

const SpotWorkerTable: React.FC<Props> = ({
  initData,
  editData,
  selectedUser,
  onSelectUser,
  handleColumnChange,
  onChangeTableData,
}) => {
  const [addWorkTimeRow2, setAddWorkTimeRow2] = React.useState<boolean>(false)
  const [addWorkTimeRow3, setAddWorkTimeRow3] = React.useState<boolean>(false)
  const centerColContainerRef = React.useRef<HTMLDivElement>(null)
  const { checkValidColumn } = useSpotWorker()

  const handleSelectedUserCheck = React.useCallback(
    (lineNumber: number) => {
      const newSelectedUser = selectedUser.includes(lineNumber)
        ? selectedUser.filter(id => id !== lineNumber)
        : selectedUser.concat([lineNumber])
      onSelectUser(newSelectedUser)
    },
    [selectedUser, onSelectUser]
  )

  React.useEffect(() => {
    if (!editData || _.isEmpty(editData)) {
      return
    }
    setAddWorkTimeRow2(editData.some(data => data.workStart2 || data.workEnd2))
    setAddWorkTimeRow3(editData.some(data => data.workStart3 || data.workEnd3))
  }, [editData])

  React.useEffect(() => {
    onChangeTableData(editData.every(data => checkValidColumn(data)))
  }, [editData, checkValidColumn, onChangeTableData])

  return (
    <div className={styles.tableWrapper} ref={centerColContainerRef}>
      <table className={styles.table}>
        <thead>
          <tr>
            <th className={`${styles.stickyCol} ${styles.left1}`}>
              <Input
                className="form-check-input"
                type="checkbox"
                onClick={e => {
                  onSelectUser(e.currentTarget.checked ? editData.map(item => item.lineNumber) : [])
                }}
                checked={selectedUser.length === editData.length}
              />
              <Label check className="ms-2">
                名前
              </Label>
            </th>
            <th className={`${styles.stickyCol} ${styles.left2}`}>シフト登録状況</th>
            <th>メンバーID</th>
            <th>配属先ワークスペース</th>
            <th>所属グループ</th>
            <th>
              <div className={`${styles.tableDataWithIcon} column-gap-2`}>
                勤務時間1
                {!addWorkTimeRow2 && (
                  <i className="icf-plus font-large text-muted" onClick={() => setAddWorkTimeRow2(true)} />
                )}
              </div>
            </th>
            {addWorkTimeRow2 && (
              <th>
                <div className={`${styles.tableDataWithIcon} column-gap-2`}>
                  勤務時間2
                  {!addWorkTimeRow3 && (
                    <i
                      className="icf-plus font-large text-muted"
                      onClick={() => setAddWorkTimeRow3(prev => (addWorkTimeRow2 ? true : prev))}
                    />
                  )}
                </div>
              </th>
            )}
            {addWorkTimeRow3 && <th>勤務時間3</th>}
            <th>識別子</th>
            <th>テンプレート</th>
            <th>スキル</th>
            <th className={`${styles.stickyCol} ${styles.right}`}>操作</th>
          </tr>
        </thead>
        <tbody>
          {editData.map(data => (
            <SpotWorkerTableColumn
              handleColumnChange={handleColumnChange}
              key={data.lineNumber}
              centerColContainerRef={centerColContainerRef}
              initDataColumn={initData.find(item => item.lineNumber === data.lineNumber)}
              editDataColumn={data}
              addWorkTimeRow2={addWorkTimeRow2}
              addWorkTimeRow3={addWorkTimeRow3}
              selectedUser={selectedUser}
              handleSelectedUserCheck={handleSelectedUserCheck}
            />
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default SpotWorkerTable
