import { configureStore } from '@reduxjs/toolkit'

import allocationReducer from 'slices/allocationsSlice'
import bopReportsReducer from 'slices/bopReportsSlice'
import bopReducer from 'slices/bopSlice'
import dashboardReducer from 'slices/dashboardSlice'
import groupsReducer from 'slices/groupsSlice'
import networkErrorDialogReducer from 'slices/networkErrorDialogSlice'
import notificationReducer from 'slices/notificationSlice'
import officialDutiesReducer from 'slices/officialDutiesSlice'
import optimizationReducer from 'slices/optimizationSlice'
import plansReducer from 'slices/plansSlice'
import postcodeReducer from 'slices/postcodeSlice'
import reportsReducer from 'slices/reportsSlice'
import scheduleTypesReducer from 'slices/scheduleTypesSlice'
import sessionReducer from 'slices/sessionSlice'
import sessionTimeoutDialogReducer from 'slices/sessionTimeoutDialogSlice'
import skillsReducer from 'slices/skillsSlice'
import spinnerReducer from 'slices/spinnerSlice'
import spotWorkerReducer from 'slices/spotWorkerSlice'
import templateReducer from 'slices/templateSlice'
import tenantsReducer from 'slices/tenantsSlice'
import usersReducer from 'slices/usersSlice'
import workResultsReducer from 'slices/workResultsSlice'
import workersReducer from 'slices/workersSlice'
import workspacesReducer from 'slices/workspacesSlice'

import type { ThunkAction, Action } from '@reduxjs/toolkit'
export const store = configureStore({
  reducer: {
    session: sessionReducer,
    skills: skillsReducer,
    tenants: tenantsReducer,
    workers: workersReducer,
    spotWorkers: spotWorkerReducer,
    workspaces: workspacesReducer,
    scheduleTypes: scheduleTypesReducer,
    officialDuties: officialDutiesReducer,
    groups: groupsReducer,
    users: usersReducer,
    reports: reportsReducer,
    spinner: spinnerReducer,
    networkErrorDialog: networkErrorDialogReducer,
    sessionTimeoutDialog: sessionTimeoutDialogReducer,
    notification: notificationReducer,
    postcode: postcodeReducer,
    optimization: optimizationReducer,
    workResults: workResultsReducer,
    allocations: allocationReducer,
    bop: bopReducer,
    bopReports: bopReportsReducer,
    plans: plansReducer,
    template: templateReducer,
    dashboard: dashboardReducer,
  },
})

export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>
