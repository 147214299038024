import { SpotWorkerShiftStatus } from 'api/spot_workers/constants'
import type { SpotWorkerShiftStatusType } from 'api/spot_workers/types'

export const UNREGISTERED_VALUE_ID = -1
export const UNSELECTED_TIME_LABEL = '--:--'

const spotWorkerShiftStatus: { [key: string]: SpotWorkerShiftStatusType } = {
  Insufficient: 1,
  InsufficientWithoutId: 2,
  ShiftUnregistered: 3,
  ShiftRegistered: 4,
  UnUpdated: 5,
  Sickout: 6,
}

export const getSpotWorkerShiftStatus = (status: SpotWorkerShiftStatusType) => {
  switch (status) {
    case spotWorkerShiftStatus.Insufficient:
      return '情報不足'
    case spotWorkerShiftStatus.InsufficientWithoutId:
      return '情報不足'
    case spotWorkerShiftStatus.ShiftUnregistered:
      return 'シフト未登録'
    case spotWorkerShiftStatus.ShiftRegistered:
      return 'シフト登録済み'
    case spotWorkerShiftStatus.UnUpdated:
      return '未更新'
    case spotWorkerShiftStatus.Sickout:
      return '欠勤'
    default:
      return '未登録'
  }
}

export const getStatusColor = (shiftStatus: SpotWorkerShiftStatusType) => {
  switch (shiftStatus) {
    case spotWorkerShiftStatus.ShiftRegistered:
      return `text-success`
    case spotWorkerShiftStatus.UnUpdated:
      return `text-warning`
    case spotWorkerShiftStatus.Sickout:
    case spotWorkerShiftStatus.InsufficientWithoutId:
    case spotWorkerShiftStatus.Insufficient:
    case spotWorkerShiftStatus.ShiftUnregistered:
      return `text-danger`
    default:
      return ``
  }
}

const Action = {
  Register: 1,
  Update: 2,
  Delete: 3,
  Sickout: 4,
} as const

export type ActionType = (typeof Action)[keyof typeof Action]
export const Actions: Record<keyof typeof Action, ActionType> = {
  Register: 1,
  Update: 2,
  Delete: 3,
  Sickout: 4,
}

export const getActionList = (status: SpotWorkerShiftStatusType) => {
  if (status === SpotWorkerShiftStatus.UnUpdated) {
    return [
      { key: Actions.Update, value: '更新' },
      { key: Actions.Delete, value: '削除' },
      { key: Actions.Sickout, value: '欠勤' },
    ]
  }
  if (status === SpotWorkerShiftStatus.ShiftUnregistered) {
    return [
      { key: Actions.Register, value: '登録' },
      { key: Actions.Delete, value: '削除' },
    ]
  }
  if (status === SpotWorkerShiftStatus.ShiftRegistered) {
    return [
      { key: Actions.Delete, value: '削除' },
      { key: Actions.Sickout, value: '欠勤' },
    ]
  }
  return [{ key: Actions.Delete, value: '削除' }]
}

const filterStatusType = {
  UnregisteredWmsMemberId: 0,
  RegisteredWmsMemberId: 1,
  New: 2,
  ShiftUnregistered: spotWorkerShiftStatus.ShiftUnregistered,
  ShiftRegistered: spotWorkerShiftStatus.ShiftRegistered,
  UnUpdated: spotWorkerShiftStatus.UnUpdated,
} as const

export type FilterStatusType = (typeof filterStatusType)[keyof typeof filterStatusType]

export const filterStatusLabel: { [key: string]: FilterStatusType } = {
  UnregisteredWmsMemberId: 0,
  RegisteredWmsMemberId: 1,
  New: 2,
  ShiftUnregistered: spotWorkerShiftStatus.ShiftUnregistered,
  ShiftRegistered: spotWorkerShiftStatus.ShiftRegistered,
  UnUpdated: spotWorkerShiftStatus.UnUpdated,
}

export const filterStatusList = [
  { key: filterStatusLabel.UnregisteredWmsMemberId, label: '識別子未設定' },
  { key: filterStatusLabel.RegisteredWmsMemberId, label: '識別子設定済み' },
  { key: filterStatusLabel.New, label: '新規' },
  { key: filterStatusLabel.ShiftRegistered, label: 'シフト登録済み' },
  { key: filterStatusLabel.ShiftUnregistered, label: 'シフト未登録' },
  { key: filterStatusLabel.UnUpdated, label: '未更新' },
]

const TimeToMinutes = (time: string | null) => {
  if (time === null) {
    return 0
  }
  const hour = parseInt(time.split(':')[0])
  const minute = parseInt(time.split(':')[1])
  return hour * 60 + minute
}

export const isValidTimeSelection = (workStart: string | null, workEnd: string | null) => {
  if (typeof workStart !== typeof workEnd) {
    return false
  }
  if (workStart === null && workEnd === null) {
    return true
  }

  const startMinutes = TimeToMinutes(workStart)
  const endMinutes = TimeToMinutes(workEnd)
  return startMinutes < endMinutes
}

export const isValidTimeContinuity = (workEnd1: string | null, workStart2: string | null) => {
  if (workEnd1 === null || workStart2 === null) {
    return true
  }
  const end1Minutes = TimeToMinutes(workEnd1)
  const start2Minutes = TimeToMinutes(workStart2)
  return end1Minutes <= start2Minutes
}
