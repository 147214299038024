import _ from 'lodash'
import * as React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { Card, CardBody } from 'reactstrap'

import { selectPlansStatus } from 'slices/plansSlice'
import { selectSkillsStatus } from 'slices/skillsSlice'

import styles from './PerformanceIndices.module.scss'

import type { PopupActions } from 'reactjs-popup/dist/types'

type Props = {
  workerId: number
  popupRef: React.RefObject<PopupActions>
}

const PerformanceIndicesPopover: React.FC<Props> = ({ workerId, popupRef }) => {
  const { plans } = useSelector(selectPlansStatus, shallowEqual)
  const { skills } = useSelector(selectSkillsStatus, shallowEqual)

  const [showMore, setShowMore] = React.useState(false)

  // ポップアップ対象ワーカー
  const selectedWorker = React.useMemo(() => {
    if (!plans) {
      return
    }
    return plans?.groups.flatMap(group => group.workersPlan).find(worker => worker.workerId === workerId) || null
  }, [plans, workerId])

  //ポップアップ対象ワーカーのスキルリスト
  const selectedWorkerSkillNames = React.useMemo(() => {
    if (!selectedWorker) {
      return []
    }

    return selectedWorker.skillIds
      .map(skillId => {
        const skill = skills.find(s => s.id === skillId)
        return skill ? skill.name : null
      })
      .filter(Boolean)
  }, [selectedWorker, skills])

  // ポップアップ対象ワーカーの人時生産性
  const selectedWorkerProductivities = React.useMemo(() => {
    if (!selectedWorker) {
      return []
    }

    const partialScheduleTypes = plans?.partialScheduleTypes || []

    return partialScheduleTypes
      .filter(scheduleType => scheduleType.connectionType !== 0)
      .map(scheduleType => {
        const matchingProductivity = selectedWorker.partialHourlyProductivities.find(
          productivity => productivity.scheduleTypeId === scheduleType.id
        )

        return {
          value: matchingProductivity ? matchingProductivity.value : scheduleType.defaultProductivity,
          unit: scheduleType.unit || '',
          defaultProductivity: scheduleType.defaultProductivity || '',
          scheduleTypeName: scheduleType.name || '',
        }
      })
  }, [selectedWorker, plans])

  React.useEffect(() => {
    if (!_.isEmpty(selectedWorkerSkillNames) || !_.isEmpty(selectedWorkerProductivities)) {
      setShowMore(true)
    }
  }, [selectedWorkerSkillNames, selectedWorkerProductivities])

  return (
    <Card>
      <CardBody>
        <div className="d-flex justify-content-between mb-2">
          <div>
            <div className="text-muted">レギュラーメンバー</div>
            <div className="fw-bold">{selectedWorker?.workerName}</div>
          </div>
          <i
            className="icf-close font-large mt-1"
            style={{ cursor: 'pointer' }}
            onClick={() => popupRef?.current?.close()}
          />
        </div>

        <div className={`mb-2 ${showMore ? styles.lineSkillNames : ''}`}>
          {selectedWorkerSkillNames?.map((skillName, index) => (
            <div key={index} className={`badge rounded-pill text-black bg-light-gray me-1 mb-1`}>
              <div className="d-flex align-items-center">
                <div className="fw-normal font-small text-truncate px-1">{skillName}</div>
              </div>
            </div>
          ))}
        </div>

        <div className={`mb-2 ${showMore ? styles.lineProductivities : ''}`}>
          {selectedWorkerProductivities?.map((p, index) => (
            <div key={index} className={`mb-1 d-inline-block w-100`}>
              <div className="d-flex justify-content-between">
                <div className={`fw-normal font-small text-truncate me-2 ${styles.schedule}`}>
                  {p.scheduleTypeName}：
                </div>
                <div className={`fw-normal font-small text-end ${styles.hour}`}>
                  {p.value || p.defaultProductivity} {p.unit}時間
                </div>
              </div>
            </div>
          ))}
        </div>

        {showMore && (
          <div
            className="text-primary cursor-pointer text-center mt-2"
            style={{ cursor: 'pointer' }}
            onClick={() => setShowMore(false)}
          >
            もっと見る
          </div>
        )}
      </CardBody>
    </Card>
  )
}

export default PerformanceIndicesPopover
