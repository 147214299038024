export const SpotWorkerShiftStatus = {
  // 情報不足
  Insufficient: 1,
  // idをのぞいて情報不足
  InsufficientWithoutId: 2,
  // シフト未登録
  ShiftUnregistered: 3,
  // シフト登録済み
  ShiftRegistered: 4,
  // 未更新
  UnUpdated: 5,
  // 欠勤
  Sickout: 6,
} as const

export const SpotWorkerAddResult = {
  ok: 0,
  invalid: 1,
  alreadyExists: 2,
} as const
